export const schemaMapycz = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Souřadnice',
        fields: ['lat', 'lon'],
      },
      {
        id: 'adresa',
        title: 'Adresa',
        fields: ['title', 'street', 'town'],
      },
      {
        id: 'fallback',
        title: 'Fallback',
        fields: ['image', 'link']
      }
    ],
    properties: {
      lat: {
        title: 'šířka',
        widget: 'number',
      }, lon: {
        title: 'délka',
        widget: 'number',
      },
      image: {
        title: 'Image',
        widget: 'object_browser',
        mode: 'image',
        selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
        allowExternals: false,
      },
      title: {
        title: 'Název',
        widget: 'text',
      },
      street: {
        title: 'Ulice a číslo',
        widget: 'text',
      },
      town: {
        title: 'PSČ a město',
        widget: 'text',
      },
      link: {
        title: 'Link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
    },
  };
};

export default schemaMapycz;
