import React from 'react';
import { Container } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

const itemLinkView = (props) => {
  const { data } = props;
  let image = data.image?.length > 0 && (
    <img
      src={data.image[0]['@id'] + '/@@images/image/preview'}
      alt={data.image[0].Title}
    />
  );
  return (
    <div className="block ch-item">
      <Container>
        <div className={data.typ ? data.typ : 'small'}>
          {data.link?.length > 0 ? (
            <UniversalLink href={data.link[0]['@id']} className="other-link">
              {image}
            </UniversalLink>
          ) : (
            image
          )}
          <div className="ch-item-text">{data.text}</div>
        </div>
      </Container>
    </div>
  );
};

export default itemLinkView;
