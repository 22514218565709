/**
 * Home container.
 * @module components/theme/NotFound/NotFound
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = () => (
  <Container className="view-wrapper">
    <h1>Vypadá to, že tato stránka neexistuje…</h1>
    <p className="description">
      Omlouváme se, za tuto nepříjemnost, nicméně na této adrese zkrátka nic
      není. Můžete se zkusit podívat do <Link to="/sitemap">mapy portálu</Link>,
      zda tam nenajdete to, co hledáte.
    </p>
    <p>
      Jste-li si jisti, že máte správnou adresu a tato chyba je ošklivá
      nespravedlnost, buďte prosím tak hodní a{' '}
      <Link to="/contact-form">napište nám</Link>.
    </p>
    <p>Děkujeme.</p>
  </Container>
);

export default withServerErrorCode(404)(NotFound);
