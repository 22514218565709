import React from 'react';
//import React, { useState, useEffect } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
//import axios from 'axios';
import { compare, getNewStart } from './helpers.jsx';

const EventCard = (props) => {
  if (!props.item.start) {
    return null;
  }
  return (
    <Grid.Column className="item" key={props.item.title}>
      <Segment className="int">
        <UniversalLink href={props.item['@id']}>
          <div className="date">
            {new Date(props.item.start).toLocaleString('cs', {
              weekday: 'short',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            })}
          </div>
          <div className="item-body">
            <h3>{props.item.title}</h3>
            <p className="location">{props.item.location}</p>
          </div>
        </UniversalLink>
      </Segment>
    </Grid.Column>
  );
};

const eventCardsTemplate = (props) => {
  /*
  const [items, setItems] = useState(props.items);
  const getItems = async function () {
    try {
      let promises = props.items.reduce((ac, val) => {
        ac.push(axios.get('/++api++' + val['@id']));
        return ac;
      }, []);
      let resps = await Promise.all(promises);
      let newitems = props.items;
      .reduce((ac, val) => {
        ac.push({
          title: val.title,
          '@id': val['@id'],
          start: val.start,
          location: val.location,
        });
        return ac;
      }, []);
      for (let i = 0; i < props.items.length; i++) {
        newitems[i].recurrence = resps[i].data.recurrence;
        newitems[i].start = getNewStart(
          newitems[i].start,
          newitems[i].recurrence,
        );
      }
      setItems(newitems);
    } catch (error) {
      console.log('%O', error);
    }
  };
  useEffect(() => {
    getItems();
    return () => {
      setItems([]); // This worked for me
    };
  }, []);
  */
  for (let i = 0; i < props.items.length; i++) {
    props.items[i].start = getNewStart(props.items[i]);
  }

  return (
    <Grid stackable columns={4}>
      {props.items.sort(compare).map((item) => (
        <EventCard item={item} key={item['@id']} />
      ))}
    </Grid>
  );
};

export default eventCardsTemplate;
