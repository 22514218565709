import React, { useState, useEffect } from 'react';
import { UniversalLink } from '@plone/volto/components';

const Mapa = (props) => {
  const { data } = props;
  const [loaded, setLoaded] = useState(false);
  const style = {
    height: '500px',
    maxWidth: '100%',
    overflow: 'hidden',
  };

  const loadMap = function loadMap() {};
  useEffect(
    function () {
      if (!loaded) {
        let script = document.getElementById('map-loader-script');
        if (script) {
          script.remove();
        }
        script = document.createElement('script');
        script.src = 'https://api.mapy.cz/loader.js';
        script.type = 'text/javascript';
        script.id = 'map-loader-script';

        script.onload = function () {
          window.Loader.async = true;
          window.Loader.load(null, null, onMapApiLoaded);
        };
        document.head.append(script);
      } else {
        const mapa = new window.SMap(
          window.JAK.gel('mapa'),
          window.SMap.Coords.fromWGS84(data.lon, data.lat),
          17,
        );
        mapa.addDefaultLayer(window.SMap.DEF_BASE).enable();
        mapa.addDefaultControls();
        var c = new window.SMap.Card();
        c.setSize(200, 100); /* Šířka, výška */

        c.getHeader().innerHTML = data.title;

        c.getBody().innerHTML = data.street + '<br>' + data.town;

        var layer = new window.SMap.Layer.Marker(); /* Vrstva pro značku */
        mapa.addLayer(layer).enable();

        var marker = new window.SMap.Marker(
          mapa.getCenter(),
        ); /* Značka s vizitkou uprostřed mapy */
        marker.decorate(window.SMap.Marker.Feature.Card, c);
        layer.addMarker(marker);

        loadMap();
      }
    },
    [loaded, data],
  );

  const onMapApiLoaded = function onMapApiLoaded() {
    setLoaded(true);
  };

  let link = null;
  let image = null;
  if (data.image?.length > 0) {
    image = (
      <img
        src={data.image[0]['@id'] + '/@@images/image/preview'}
        alt={data.image[0].Title}
      />
    );
  }
  if (data.link?.length > 0) {
    link = (
      <UniversalLink href={data.link[0]['@id']}>
        {image ? image : 'tento odkaz'}
      </UniversalLink>
    );
  }
  let stat;
  if (link) {
    if (image) {
      stat = (
        <>
          <p>
            On-line mapu se nepodařilo načíst... Kliknutím na obrázek přejdete
            na Mapy.cz.
          </p>
          {link}
        </>
      );
    } else {
      stat = (
        <>
          <p>
            On-line mapu se nepodařilo načíst... Kliknutím na {link} přejdete na
            Mapy.cz.
          </p>
        </>
      );
    }
  } else {
    if (image) {
      stat = (
        <>
          <p>
            On-line mapu se nepodařilo načíst... Níže je statický obrázek mapy.
          </p>
          {image}
        </>
      );
    } else {
      stat = (
        <>
          <p>On-line mapu se nepodařilo načíst...</p>
        </>
      );
    }
  }

  return (
    <>
      <div className="mapycz">
        {loaded ? <div id="mapa" style={style} /> : stat}
      </div>
    </>
  );
};

const mapyczView = (props) => {
  const { data } = props;
  return <Mapa data={data} />;
};

export default mapyczView;
