import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, Component } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

import { compare, getNewStart } from './helpers.jsx';

const SummaryTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }
  for (let item of items) {
    if (item.preview_image) {
      item.image_field = 'preview_image';
    }
    if (item.image) {
      item.image_field = 'image';
    }
    item.start = getNewStart(item);
  }

  return (
    <>
      <div className="items">
        {items.sort(compare).map((item) => (
          <div className="listing-item" key={item['@id']}>
            <ConditionalLink item={item} condition={!isEditMode}>
              <Component componentName="PreviewImage" item={item} alt="" />
              <div className="listing-body">
                {item.start && (
                  <div className="date">
                    {new Date(item.start).toLocaleString('cs', {
                      weekday: 'short',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: '2-digit',
                    })}
                  </div>
                )}
                <h3>{item.title ? item.title : item.id}</h3>
                <p>{item.description}</p>
                {item['@type'] === 'News Item' &&
                  (item.modified || item.created) && (
                    <div className="aktualizace">
                      Poslední aktualizace:{' '}
                      {new Date(item.modified || item.created).toLocaleString(
                        'cs',
                        {
                          month: 'long',
                          day: 'numeric',
                        },
                      )}
                    </div>
                  )}
              </div>
            </ConditionalLink>
          </div>
        ))}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

SummaryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default SummaryTemplate;
