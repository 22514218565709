/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

import {
  ItemBlockView,
  ItemBlockEdit,
  eventCardsTemplate,
  galleryTemplate,
  SummaryTemplate,
  VizitkaBlockView,
  VizitkaBlockEdit,
  teaserTemplate,
  TeaserListBlockEdit,
  TeaserListBlockView,
  MapyczBlockView,
  MapyczBlockEdit,
  MetadataBlockView,
  MetadataBlockEdit,
  TimelineBlockView,
  TimelineBlockEdit,
} from '@package/components';
import heroSVG from '@plone/volto/icons/hero.svg';
import applicationSVG from '@plone/volto/icons/application.svg';
import menuSVG from '@plone/volto/icons/menu.svg';
import mapSVG from '@plone/volto/icons/map.svg';
import infoSVG from '@plone/volto/icons/info.svg';
import calendarSVG from '@plone/volto/icons/calendar.svg';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['cs'],
    defaultLanguage: 'cs',
  };
  config.blocks.blocksConfig.item = {
    id: 'item',
    title: 'Item',
    icon: heroSVG,
    group: 'common',
    view: ItemBlockView,
    edit: ItemBlockEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.vizitka = {
    id: 'vizitka',
    title: 'Vizitka',
    icon: applicationSVG,
    group: 'common',
    view: VizitkaBlockView,
    edit: VizitkaBlockEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.__grid.gridAllowedBlocks = [
    'teaser',
    'image',
    'listing',
    'text',
    'card',
    'item',
    'vizitka',
    'teaserList',
    'timeline',
  ];
  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'eventListing',
      title: 'Karty událostí',
      template: eventCardsTemplate,
      fullobjects: 1,
    },
    {
      id: 'galleryListing',
      title: 'Galerie',
      template: galleryTemplate,
    },
    {
      id: 'summary',
      title: 'Souhrn',
      template: SummaryTemplate,
      fullobjects: 1,
      isDefault: 1,
    },
  ];
  config.blocks.blocksConfig.teaser.variations = [
    ...config.blocks.blocksConfig.teaser.variations,
    {
      id: 'teaserTemplate',
      title: 'Template',
      template: teaserTemplate,
    },
  ];
  config.blocks.blocksConfig.teaserList = {
    id: 'teaserList',
    title: 'Teaser List',
    icon: menuSVG,
    group: 'common',
    view: TeaserListBlockView,
    edit: TeaserListBlockEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.mapycz = {
    id: 'mapycz',
    title: 'Mapy.cz',
    icon: mapSVG,
    group: 'common',
    view: MapyczBlockView,
    edit: MapyczBlockEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.metadata = {
    id: 'metadata',
    title: 'Metadata',
    icon: infoSVG,
    group: 'common',
    view: MetadataBlockView,
    edit: MetadataBlockEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.timeline = {
    id: 'timeline',
    title: 'Timeline',
    icon: calendarSVG,
    group: 'common',
    view: TimelineBlockView,
    edit: TimelineBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };
  //console.log(config.views.contentTypesViews);
  return config;
}
