export const schemaItem = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['image', 'text', 'typ'],
      },
      {
        id: 'link',
        title: 'Links',
        fields: ['link'],
      },
    ],
    properties: {
      image: {
        title: 'Image',
        widget: 'object_browser',
        mode: 'image',
        selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
        allowExternals: true,
      },
      text: {
        title: 'Text',
        widget: 'text',
      },
      typ: {
        title: 'Typ',
        widget: 'select',
        choices: [
          ['small', 'maly'],
          ['medium', 'stredni'],
        ],
      },
      link: {
        title: 'Link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
    },
  };
};

export default schemaItem;
