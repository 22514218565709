import React from 'react';
import { ConditionalLink, Component } from '@plone/volto/components';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';

const teaserTemplate = (props) => {
  const data = props.data;
  if (!(data.href?.length > 0)) {
    if (props.isEditMode) {
      return (
        <>
          <div className="block ch-document-preview">
            <div className="ch-document-preview-card">
              <img src={imageBlockSVG} alt="" />
              <div className="text">
                <h3>Vyberte prosím existující element jako cíl odkazu</h3>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
  return (
    <>
      <div className="block ch-document-preview">
        <ConditionalLink item={data.href[0]} condition={!props.isEditMode}>
          <div className="ch-document-preview-card">
            <Component
              componentName="PreviewImage"
              item={
                data.preview_image?.length > 0
                  ? data.preview_image[0]
                  : data.href[0]
              }
              alt=""
            />
            <div className="text">
              <h3>{data.title}</h3>
              <p>{data.description}</p>
            </div>
          </div>
        </ConditionalLink>
      </div>
    </>
  );
};

export default teaserTemplate;
