export const schemaItem = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['heading', 'list'],
      },
    ],
    properties: {
      heading: {
        title: 'heading',
        widget: 'text',
      },
      list: {
        title: 'List',
        widget: 'object_browser',
        mode: 'multiple',
        allowExternals: false,
      },
    },
  };
};

export default schemaItem;
