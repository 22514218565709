export const schemaMetadata = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['metadata'],
      },
    ],
    properties: {
      metadata: {
        title: 'Zobrazit Metadata',
        widget: 'select',
        choices: [
          ['created', 'vytvořeno'],
          ['modified', 'změněno'],
          ['both', 'obojí'],
        ],
      },
    },
  };
};

export default schemaMetadata;
