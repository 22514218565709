import React from 'react';

const metadataView = (props) => {
  const { data, properties } = props;
  return (
    <div className="block ch-metadata">
      {((data.metadata && data.metadata === 'created') ||
        data.metadata === 'both') &&
        properties.created && (
          <div className="ch-metadata-in">
            Přidáno{' '}
            {new Date(properties.created).toLocaleString('cs', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
            })}
            {data.metadata && data.metadata === 'both' && ', '}
          </div>
        )}
      {((data.metadata && data.metadata === 'modified') ||
        data.metadata === 'both') &&
        properties.modified && (
          <div className="ch-metadata-in">
            Aktualizace{' '}
            {new Date(properties.modified).toLocaleString('cs', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
            })}
          </div>
        )}
      {data.metadata && (
        <div className="ch-metadata-in">
          |{' '}
          {(properties.creators || [])
            .concat(
              (properties.contributors || []).filter(
                (item) => properties.creators.indexOf(item) < 0,
              ),
            )
            .join(', ')}
        </div>
      )}
    </div>
  );
};

export default metadataView;
