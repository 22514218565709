import { rrulestr } from 'rrule';

const getNewStart = function (e) {
  if (!e.start) {
    return undefined;
  }
  let now = new Date();
  if (!e.recurrence) {
    return new Date(e.start);
  }
  for (let date of rrulestr(e.recurrence).all()) {
    if (now < date) {
      return new Date(
        date.toISOString().slice(0, 11) +
          new Date(e.start).toISOString().slice(11),
      );
    }
  }
  return null;
};

const compare = function (a, b) {
  if (a.start < b.start) {
    return -1;
  }
  if (a.start > b.start) {
    return 1;
  }
  return 0;
};

export { compare, getNewStart };
