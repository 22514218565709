export const schemaVizitka = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['image', 'title', 'text'],
      },
    ],
    properties: {
      image: {
        title: 'Image',
        widget: 'object_browser',
        mode: 'image',
        selectedVizitkaAttrs: ['Title', 'Description', '@type', '@id'],
        allowExternals: true,
      },
      title: {
        title: 'Title',
        widget: 'text',
      },
      text: {
        title: 'Text',
        widget: 'textarea',
      },
    },
  };
};

export default schemaVizitka;
