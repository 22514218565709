import React from 'react';
import { UniversalLink } from '@plone/volto/components';

const PreviewCard = (props) => {
  return (
    <div className="ch-document-preview-card">
      <img
        src={props.item['@id'] + '/@@images/preview_image/preview'}
        alt={props.item.Title}
      />
      <div className="text">
        <h3>{props.item.Title}</h3>
        <p>{props.item.Description}</p>
      </div>
    </div>
  );
};

const teaserListView = (props) => {
  const { data } = props;
  return (
    <div className="block ch-document-preview-list">
      <h3 className="ch-document-preview-list-heading">{data.heading}</h3>
      {data.list?.length > 0 &&
        data.list.map((item) => {
          return (
            <div className="ch-document-preview" key={item['@id']}>
              {!props.editable ? (
                <UniversalLink href={item['@id']}>
                  <PreviewCard item={item} />
                </UniversalLink>
              ) : (
                <PreviewCard item={item} />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default teaserListView;
