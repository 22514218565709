/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import openclipart from './openclipart.png';

import { Anontools, UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = (props) => {
  const intl = props.intl;
  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      textAlign="center"
      id="footer"
      className="footercolour"
    >
      <Container>
        <List horizontal inverted>
          <div role="listitem" className="item">
            <UniversalLink className="item" href="/sitemap">
              Mapa portálu
            </UniversalLink>
          </div>
          <div role="listitem" className="item">
            <UniversalLink className="item" href="/kontakty">
              <FormattedMessage id="Kontakty" defaultMessage="Kontakty" />
            </UniversalLink>
          </div>
          <div role="listitem" className="item">
            {!props.token && <Anontools />}
          </div>
        </List>
        <Segment basic inverted className="footercolour discreet">
          Powered by{' '}
          <UniversalLink href="https://plone.org/">Plone</UniversalLink>, Art
          from{' '}
          <UniversalLink href="https://openclipart.org/">
            <img src={openclipart} alt="Openclipart" /> Openclipart,
          </UniversalLink>{' '}
          <abbr title={intl.formatMessage(messages.copyright)}>©</abbr>{' '}
          <UniversalLink href="/">Centrum Christina</UniversalLink> 2022-
          {new Date().getFullYear()}
        </Segment>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
