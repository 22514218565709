import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { Dimmer, Image, Icon } from 'semantic-ui-react';

const GalleryImage = (props) => {
  return (
    <Dimmer
      active={props.active}
      onClickOutside={props.handleClose}
      page
      className="GalleryImage"
    >
      <Image
        src={props.items[props.index]['@id'] + '/@@images/image'}
        centered
      />
      <p className="description">
        {props.items[props.index].Description !== ''
          ? props.items[props.index].Description
          : ''}
      </p>
      <Icon
        name="angle left"
        size="huge"
        onClick={() =>
          props.setIndex(
            props.index !== 0 ? props.index - 1 : props.items.length - 1,
          )
        }
      />
      <Icon
        name="angle right"
        size="huge"
        onClick={() =>
          props.setIndex(
            props.index !== props.items.length - 1 ? props.index + 1 : 0,
          )
        }
      />
      <Icon name="delete" size="huge" onClick={props.handleClose} />
    </Dimmer>
  );
};

const GalleryTemplate = (props) => {
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(0);
  const handleClose = () => {
    setActive(false);
  };
  return (
    <>
      <GalleryImage
        active={active}
        handleClose={handleClose}
        items={props.items}
        index={index}
        setIndex={setIndex}
      />
      <Grid stackable columns={8}>
        {props.items.map((element, i, array) => (
          <Grid.Column key={element['@id']} width={2}>
            <Image
              size="small"
              src={
                element['@id'] +
                '/' +
                element.image_scales.image[0].scales.preview.download
              }
              onClick={() => {
                setIndex(i);
                setActive(true);
              }}
            />
          </Grid.Column>
        ))}
      </Grid>
    </>
  );
};
//            <GalleryImage image={element} index={index} items={array} />

export default GalleryTemplate;
