import React from 'react';

const vizitkaLinkView = (props) => {
  const { data } = props;
  return (
    <div className="block ch-vizitka">
      <header className="ch-vizitka-title">
        <h2>{data.title}</h2>
      </header>
      {data.image?.length > 0 && (
        <img
          src={data.image[0]['@id'] + '/@@images/image/preview'}
          alt={data.image[0].Title}
        />
      )}
      <div className="ch-vizitka-text">{data.text}</div>
    </div>
  );
};

export default vizitkaLinkView;
